import { useRouter } from 'next/router';
import { Divider, Flex, Heading, LineItemCard, Paragraph, PrescriptionSubmission, Price } from '@components';
import { formatCurrency } from '@utils/shopify';
import { OrderDetailPOMS, OrderStatusPOMS } from '@ts/poms/orders';
import variables from '@styles/export.module.scss';
import { LOCALE_CODES, PRODUCT_TYPES } from '@constants';
import styles from './Receipt.module.scss';

type ReceiptProps = {
	order: OrderDetailPOMS;
	isLogIn?: boolean;
};

const Receipt = ({ order, isLogIn = true }: ReceiptProps) => {
	const {
		discount_codes = [],
		line_items,
		non_rx_discount_codes = [],
		manual_discount_codes = [],
		pd_needed,
		order_number,
		shipping_lines = [],
		statuses,
		subtotal_price,
		total_discounts,
		total_price,
		total_tax,
		billing_address: { province_code: billState },
		shipping_address: { province_code: shipState },
		currency,
		current_total_price_set: currTotal,
		current_subtotal_price_set: currSubtotal,
		current_total_tax_set: currTax,
		current_total_discounts_set: currDiscounts,
	} = order;
	const { locale } = useRouter();
	const showCurr = locale === LOCALE_CODES.AU || locale === LOCALE_CODES.CA;
	let totalPrice = total_price;
	let subtotalPrice = subtotal_price;
	let totalTax = total_tax;
	let totalDiscounts = total_discounts;
	if (!!currTotal) {
		const { amount, currency_code: currencyCode }: any = currTotal.presentment_money;
		totalPrice = formatCurrency({ amount, minDigits: 2, maxDigits: 2, currencyCode }, showCurr);
	}
	if (!!currSubtotal) {
		const { amount, currency_code: currencyCode }: any = currSubtotal.presentment_money;
		subtotalPrice = formatCurrency({ amount, minDigits: 2, maxDigits: 2, currencyCode }, showCurr);
	}
	if (!!currTax) {
		const { amount, currency_code: currencyCode }: any = currTax.presentment_money;
		totalTax = formatCurrency({ amount, minDigits: 2, maxDigits: 2, currencyCode }, showCurr);
	}
	if (!!currDiscounts) {
		const { amount, currency_code: currencyCode }: any = currDiscounts.presentment_money;
		totalDiscounts = formatCurrency({ amount, minDigits: 2, maxDigits: 2, currencyCode }, showCurr);
	}

	const noManualRXStates = new Set(['CA', 'NY', 'TX', 'ID', 'IL']);
	const limitManualRX = noManualRXStates.has(billState) || noManualRXStates.has(shipState);
	const isCancelled = !!statuses.findLast((status: OrderStatusPOMS) => status.title === 'Cancelled');
	const discountsArray = [...non_rx_discount_codes, ...manual_discount_codes, ...discount_codes];

	const mappedLineItems = line_items.map(item => (
		<LineItemCard key={`line-item-${item.id}`} data={item} dataSource={'poms'} noInteraction />
	));

	const mappedDiscounts = discountsArray?.length ? (
		discountsArray.map(({ amount, code }) => (
			<Price
				key={code}
				label={`Promotion ${!!code ? `(${code}) ` : ''}`}
				price={`(${formatCurrency({ amount, minDigits: 2, maxDigits: 2, currencyCode: currency }, showCurr)})`}
			/>
		))
	) : (
		<Price label='Total Discount' price={totalDiscounts} />
	);

	const mappedShippingLines = shipping_lines?.map(({ title, price, price_set }: any) => {
		const localPrice = price_set?.presentment_money?.amount ?? price;
		const localCurrency = price_set?.presentment_money?.currency_code ?? currency;
		return (
			<Price
				key={title}
				label={title}
				price={formatCurrency({ amount: localPrice, minDigits: 2, maxDigits: 2, currencyCode: localCurrency }, showCurr)}
			/>
		);
	});

	return (
		<div className={styles.details}>
			{isLogIn && !isCancelled && (
				<div className={styles['prescription-container']}>
					<div className={styles['prescription-list']}>
						{line_items.map((line, index) => {
							const job = order.jobs.find(job => job.id === line.job_id);
							if (line.product_type.includes(PRODUCT_TYPES.BASE_FRAME)) {
								return (
									<div key={`prescription ${index}`}>
										<Heading tag='h6' style={{ marginBottom: '0.2rem' }}>
											Prescription Details
										</Heading>
										<Paragraph style={{ marginBottom: '1.6rem' }}>
											for {line.product_title} in {line.variant_title}
										</Paragraph>
										<PrescriptionSubmission
											key={line.id}
											line={{
												...line,
												rx_needed: line.rx_needed,
											}}
											isPdNeeded={pd_needed}
											orderNumber={order_number}
											submission_method={line.submission_method}
											prescription_image={job?.prescription_image}
											limitManualRX={limitManualRX}
										/>
									</div>
								);
							}
						})}
					</div>
				</div>
			)}
			<Heading tag='h6' style={{ marginBottom: '1.6rem' }}>
				Items in this order
			</Heading>
			<div className={styles.container}>
				<Flex column gap={3} className={styles.list}>
					{mappedLineItems}
				</Flex>
				<Divider color={variables.gray2} alignment='horizontal' />
				<Price label='Subtotal' price={subtotalPrice} />
				{mappedShippingLines}
				{mappedDiscounts}
				<Price label='Tax' price={totalTax} />
				<Divider color={variables.gray2} alignment='horizontal' />
				<Price label='Total' isHeading={true} headingTag='h6' price={totalPrice} />
			</div>
		</div>
	);
};

export default Receipt;
